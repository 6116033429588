import InternalLink from 'components/InternalLink';
import FullScreenFallbackLayout from 'components/layout/FullScreenFallbackLayout';
import PageLayout from 'components/layout/PageLayout';
import Whoops from 'components/Whoops';
import { useNavigate } from 'react-router-dom';
import { useGetUserApplicationByIdQuery } from 'redux/modules/application';
import { useGetAssetQuery } from 'redux/modules/asset';
import { useGetOfferByIdQuery } from 'redux/modules/offer';
import { URLS } from 'routes/urls';

import OfferForm from '../../Tabs/ApproveFirmTab/OfferSection/OfferForm';
import Header from '../Header';

interface OffersCreateProps {
    id: string;
    offerId: string;
    userId: string;
}

export default function UpdateOffer({ id, offerId, userId }: OffersCreateProps) {
    const navigate = useNavigate();

    const { data: application, isLoading: isLoadingApplication } = useGetUserApplicationByIdQuery({
        applicationId: id,
        userId,
    });

    const { data: asset, isLoading: isLoadingAsset } = useGetAssetQuery(
        application?.building_suite_id ?? '',
        {
            skip: !application?.building_suite_id,
        }
    );

    const { data: offer, isLoading: isLoadingOffer } = useGetOfferByIdQuery(offerId);

    const isLoading = isLoadingApplication || isLoadingAsset || isLoadingOffer;

    if (!asset || !offer || !application || isLoading) {
        return (
            <FullScreenFallbackLayout isLoading={isLoading}>
                <Whoops message="An error occurred while loading the offer.">
                    Unable to load the offer {offerId}.{' '}
                    <InternalLink
                        to={URLS.Applications.Detail.buildPath(
                            { id, userId },
                            { tab: 'approve-firm' }
                        )}
                    >
                        Return to the application approval tab.
                    </InternalLink>
                </Whoops>
            </FullScreenFallbackLayout>
        );
    }

    return (
        <PageLayout header={<Header applicationId={id} offer={offer} userId={userId} />}>
            <OfferForm
                asset={asset}
                offer={offer}
                onSuccessfulSubmit={() =>
                    navigate(
                        URLS.Applications.Detail.buildPath({ id, userId }, { tab: 'approve-firm' })
                    )
                }
            />
        </PageLayout>
    );
}
