import { Offer } from '@keyliving/shared-types';
import { PageHeader } from 'components/layout/PageLayout';
import { useGetUserQuery } from 'redux/modules/auth';
import { URLS } from 'routes/urls';
import { getFormattedAddressFromOffer } from 'utils/offer';

import OfferStatusBadge from '../OfferStatusBadge';
import classes from './Header.module.scss';

interface HeaderProps {
    applicationId: string;
    offer?: Offer;
    userId: string;
}

export default function Header({ applicationId, offer, userId }: HeaderProps) {
    const { data } = useGetUserQuery(userId);

    const formattedAddress = getFormattedAddressFromOffer(offer);

    return (
        <PageHeader
            backUrl={URLS.Applications.Detail.buildPath(
                { id: applicationId, userId },
                { tab: 'approve-firm' }
            )}
            canGoBack
            title={formattedAddress || 'Create Offer'}
        >
            <div className={classes.info_subcontainer}>
                <div className={classes.user_name}>{data?.user?.name || 'User Name'}</div>
                <div className={classes.user_subtitle}>Applicant</div>
            </div>
            <div className={classes.info_subcontainer}>
                <div className={classes.status_container}>
                    <OfferStatusBadge size="md" status={offer?.status} />
                </div>
            </div>
        </PageHeader>
    );
}
