import { IconProps } from './types';

export default function FilterIcon({ height, width, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 19.5 16.39"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M.8 3.48h2.24a2.73 2.73 0 0 0 2.62 2h.51a2.72 2.72 0 0 0 2.61-2h10a.75.75 0 0 0 0-1.5h-10a2.72 2.72 0 0 0-2.61-2h-.51a2.73 2.73 0 0 0-2.62 2H.8a.75.75 0 0 0 0 1.5Zm4.86-2h.51a1.23 1.23 0 0 1 0 2.46h-.51a1.23 1.23 0 1 1 0-2.46ZM18.8 7.44h-2.3a2.72 2.72 0 0 0-2.61-2h-.51a2.73 2.73 0 0 0-2.62 2H.8a.75.75 0 0 0 0 1.5h10a2.73 2.73 0 0 0 2.62 2h.51a2.72 2.72 0 0 0 2.61-2h2.26a.75.75 0 0 0 0-1.5Zm-4.86 2h-.51a1.23 1.23 0 1 1 0-2.46h.51a1.23 1.23 0 1 1 0 2.46ZM18.71 12.91h-10a2.73 2.73 0 0 0-2.61-2h-.51a2.74 2.74 0 0 0-2.62 2H.8a.75.75 0 0 0 0 1.5h2.24a2.73 2.73 0 0 0 2.62 2h.51a2.72 2.72 0 0 0 2.61-2h10a.75.75 0 0 0 0-1.5Zm-12.59 2h-.51a1.24 1.24 0 1 1 0-2.47h.51a1.24 1.24 0 0 1 0 2.47Z"
                fill="currentColor"
            />
        </svg>
    );
}
