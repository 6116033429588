import { Component, ErrorInfo, ReactNode } from 'react';

import FullScreenFallbackLayout from '../layout/FullScreenFallbackLayout';
import Whoops from '../Whoops';
import classes from './ErrorBoundary.module.scss';

interface Props {
    children?: ReactNode;
}

interface State {
    hasError: boolean;
}

export default class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
    };

    public static getDerivedStateFromError(_: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.error('Uncaught error:', error, errorInfo);
        }
    }

    public render() {
        if (this.state.hasError) {
            return (
                <FullScreenFallbackLayout>
                    <Whoops message="Application Error">
                        <div className="content">
                            <p>
                                Looks like something went wrong that we can&lsquo;t recover from.
                                <br />
                                If the issue continues, please contact support.
                            </p>
                            <p>
                                <a className={classes.reset} href="/">
                                    Reset Application
                                </a>
                            </p>
                        </div>
                    </Whoops>
                </FullScreenFallbackLayout>
            );
        }

        return this.props.children;
    }
}
