import { CONTRACT_CONDITION_FORMULA } from '@keyliving/shared-types';

import toMoney from '../toMoney';
import toPercent from '../toPercent';

/**
 * Format a contracts value based on it's formula type
 */
export default function formatContractValueByFormula(
    value: number,
    formula: CONTRACT_CONDITION_FORMULA
) {
    switch (formula) {
        /**
         * There were cases where we had a BINARY contract we were adding values for
         * not just 1/0. So we need to check > 0 not just 1 || 0.
         */
        case CONTRACT_CONDITION_FORMULA.BINARY:
            return value > 0 ? 'Enabled' : 'Disabled';

        case CONTRACT_CONDITION_FORMULA.YEAR:
            return `${value} Year${value > 1 ? 's' : ''}`;

        case CONTRACT_CONDITION_FORMULA.PERCENTAGE:
            return toPercent(value);

        default:
            return toMoney(value);
    }
}
