import { ReactElement } from 'react';

import { IconProps } from '../../icons';
import Button from '../Button';
import classes from './Breadcrumb.module.scss';

interface CrumbProps {
    /** Icon to display to left of folder name */
    icon?: ReactElement<IconProps>;
    /** Is this the current folder */
    isLastCrumb: boolean;
    /** Force a label instead of name taken from folder path */
    label?: string;
    /** onClick handler for the crumb button */
    onClick: (path: string) => void;
    /** Full path to this current point in the breadcrumb trail */
    path: string;
}

export default function Crumb({ icon, isLastCrumb, label, onClick, path }: CrumbProps) {
    const name = label ? label : path.substring(path.lastIndexOf('/') + 1);

    if (isLastCrumb) {
        return (
            <span aria-current="page" className={classes.crumb} data-testid="lastCrumb">
                {icon ? <span className={classes.crumb__icon}>{icon}</span> : null}
                {name}
            </span>
        );
    }

    return (
        <Button
            className={classes.crumb}
            fill="transparent"
            icon={icon}
            onClick={(e) => {
                e.stopPropagation();
                onClick(path);
            }}
            size="md"
            theme="tertiary"
            type="button"
        >
            {name}
        </Button>
    );
}
