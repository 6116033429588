import { useCallback, useEffect, useRef } from 'react';

type Value = Omit<google.maps.GeocoderAddressComponent, 'types'>;
export interface AutoCompleteAddressValues {
    city: Value | null;
    country: Value | null;
    postalZip: Value | null;
    region: Value | null;
    street: Value | null;
    streetNumber: Value | null;
}

// @src - https://gist.github.com/admicaa/abfbb07507b57336fb491b92b90860c2#file-google-maps-parser-js
// @src - https://developers.google.com/maps/documentation/geocoding/requests-geocoding#Types
var translationLookup: Record<keyof AutoCompleteAddressValues, ReadonlyArray<string>> = {
    streetNumber: ['street_number'],
    postalZip: ['postal_code'],
    street: ['street_address', 'route'],
    region: ['administrative_area_level_1'],
    city: ['locality'],
    country: ['country'],
};

/**
 * Translate an ugly array of GeocoderAddressComponent's to a more user friendly format.
 *
 * e.g. [{ long_name: "1234", short_name: "1234", types: ['administrative_area_level_1', 'political']
 * -> { provinceState: { long_name: "1234", short_name: "1234" }}
 */
function transformPlaceResultToAddressValues(
    result: google.maps.places.PlaceResult
): AutoCompleteAddressValues {
    // Loop over the lookup...
    return (
        Object.entries(translationLookup) as [
            keyof AutoCompleteAddressValues,
            ReadonlyArray<string>
        ][]
    ).reduce((accumulator, [key, typesArray]) => {
        //... find the address_component who's types include the types from the lookup
        const foundComponent = result.address_components?.find(({ types }) => {
            const intersection = types.filter((x) => typesArray.includes(x));

            return intersection.length > 0;
        });

        if (foundComponent) {
            accumulator[key] = { ...foundComponent };
        } else {
            accumulator[key] = null;
        }

        return accumulator;
    }, {} as AutoCompleteAddressValues);
}

interface UseSetupAutoCompleteProps {
    autoCompleteInputRef: React.RefObject<HTMLInputElement | null>;
    onAutoComplete: (addressValues: AutoCompleteAddressValues) => void;
}

export default function useSetupAutoComplete({
    autoCompleteInputRef, // Ref to autocomplete input element
    onAutoComplete, // Callback to handle the resolved address values
}: UseSetupAutoCompleteProps) {
    const autoCompleteRef = useRef<google.maps.places.Autocomplete>();
    const setupAutoComplete = useCallback(
        async (input: HTMLInputElement) => {
            const { Autocomplete } = (await google.maps.importLibrary(
                'places'
            )) as google.maps.PlacesLibrary;

            autoCompleteRef.current = new Autocomplete(input, {
                componentRestrictions: {
                    country: ['CA'], // Limit results to Canada
                },
                fields: ['address_components'],
                types: ['address'],
            });

            autoCompleteRef.current.addListener('place_changed', () => {
                const place = autoCompleteRef.current?.getPlace();

                if (place) {
                    const resolvedPlace = transformPlaceResultToAddressValues(place);
                    const { city, country, postalZip, region, street, streetNumber } =
                        resolvedPlace;

                    onAutoComplete({
                        city,
                        country,
                        postalZip,
                        region,
                        street,
                        streetNumber,
                    });
                }
            });
        },
        [onAutoComplete]
    );

    useEffect(() => {
        if (autoCompleteInputRef.current) {
            setupAutoComplete(autoCompleteInputRef.current);
        }
    }, [setupAutoComplete, autoCompleteInputRef]);
}
