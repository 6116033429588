import { IconProps } from './types';

export default function UserIcon({ height = 24, width = 24, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M12 15.75c-3.31 0-6-2.69-6-6s2.69-6 6-6 6 2.69 6 6-2.69 6-6 6Zm0-10.5c-2.48 0-4.5 2.02-4.5 4.5s2.02 4.5 4.5 4.5 4.5-2.02 4.5-4.5-2.02-4.5-4.5-4.5Z"
                fill="currentColor"
            />
            <path
                d="M24 12c0-6.62-5.38-12-12-12S0 5.38 0 12s5.38 12 12 12c2.75 0 5.28-.94 7.31-2.5.04-.03.09-.05.13-.09 0 0 0-.02.02-.02C22.22 19.19 24 15.8 24 12ZM1.5 12C1.5 6.21 6.21 1.5 12 1.5S22.5 6.21 22.5 12c0 3.14-1.39 5.95-3.58 7.87a10.424 10.424 0 0 0-6.89-2.62H12c-2.56 0-4.98.92-6.92 2.63-2.19-1.93-3.58-4.74-3.58-7.87Zm4.79 8.8c1.61-1.33 3.59-2.05 5.74-2.05 2.1 0 4.09.73 5.69 2.05-1.65 1.07-3.61 1.7-5.71 1.7s-4.07-.63-5.71-1.7Z"
                fill="currentColor"
            />
        </svg>
    );
}
