import { numberOrNull, stringOrNull } from 'utils/validation';
import { number, object, preprocess, string } from 'zod';

import { REQUIRED_MSG } from '../../../../../../../constants';

const validation = object({
    buildingUnit: preprocess(
        stringOrNull,
        string({
            invalid_type_error: REQUIRED_MSG,
        })
            .nullable()
            .optional()
    ),
    buildingCity: preprocess(
        stringOrNull,
        string({
            required_error: REQUIRED_MSG,
            invalid_type_error: REQUIRED_MSG,
        }).max(50)
    ),
    buildingCountry: preprocess(
        stringOrNull,
        string({
            required_error: REQUIRED_MSG,
            invalid_type_error: REQUIRED_MSG,
        }).max(50)
    ),
    buildingPostalCode: preprocess(
        stringOrNull,
        string({
            required_error: REQUIRED_MSG,
            invalid_type_error: REQUIRED_MSG,
        }).max(25)
    ),
    buildingRegion: preprocess(
        stringOrNull,
        string({
            required_error: REQUIRED_MSG,
            invalid_type_error: REQUIRED_MSG,
        }).max(50)
    ),
    buildingStreet: preprocess(
        stringOrNull,
        string({
            required_error: REQUIRED_MSG,
            invalid_type_error: REQUIRED_MSG,
        }).max(100)
    ),
    dateSubmitted: preprocess(
        stringOrNull,
        string({
            invalid_type_error: REQUIRED_MSG,
        })
            .nullable()
            .optional()
    ),
    listPrice: preprocess(
        numberOrNull,
        number({
            required_error: REQUIRED_MSG,
            invalid_type_error: REQUIRED_MSG,
        }).positive()
    ),
    maxHomeBudget: preprocess(
        numberOrNull,
        number({
            required_error: REQUIRED_MSG,
            invalid_type_error: REQUIRED_MSG,
        }).positive()
    ),
    notes: preprocess(
        stringOrNull,
        string({
            invalid_type_error: REQUIRED_MSG,
        })
            .max(500)
            .nullable()
    ),
    offerPrice: preprocess(
        numberOrNull,
        number({
            required_error: REQUIRED_MSG,
            invalid_type_error: REQUIRED_MSG,
        }).positive()
    ),
});

export default validation;
