import '@keyliving/component-lib/dist/css/global.css';

import PrivateRoute from 'components/PrivateRoute';
import { useRoutes } from 'react-router-dom';

import HydrationGate from './components/HydrationGate';
import { authRoutes, publicRoutes } from './routes/routes';

export default function App() {
    const routes = useRoutes([
        ...publicRoutes,
        {
            /**
             * Our authRoutes are wrapped in the PrivateRoute component so if a user
             * navigates to them and is not logged in, they are redirected to the
             * login page vs getting a 404 if we didn't create them.
             */
            path: '/',
            element: <PrivateRoute />,
            children: authRoutes,
        },
    ]);

    return <HydrationGate>{routes}</HydrationGate>;
}
