import { CONTRACT_CONDITION_TYPE } from '@keyliving/shared-types';

export const REQUIRED_MSG = 'This field is required';

export const DOWN_PAYMENT_CONDITIONS = [
    CONTRACT_CONDITION_TYPE.DOWNPAYMENT_FIXED,
    CONTRACT_CONDITION_TYPE.DOWNPAYMENT_PERCENTAGE,
];

export const VALUATION_METHOD_CONDITIONS = [
    CONTRACT_CONDITION_TYPE.HOME_PRICE_INDEX_VALUATION_METHOD,
    CONTRACT_CONDITION_TYPE.FIXED_GROWTH_VALUATION_METHOD,
];
