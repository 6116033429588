import { useGlobalAuth } from '@keyliving/component-lib';
import { isTokenExpired } from '@keyliving/utils';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { authService, setClaims, setCurrentUser } from 'redux/modules/auth';
import { AppStore, setupStore } from 'redux/store';

import FullScreenFallbackLayout from './layout/FullScreenFallbackLayout';

interface HydrationGateProps {
    children: ReactElement | null;
}

let store: null | AppStore = null;

/**
 * Create the store and if we have a valid token, pre-populate
 * the token and claims.
 *
 * @param token authToken taken from Auth Cookie
 * @returns the store and a flag indicating if we should hydrate
 */
async function createStore(
    token?: string
): Promise<{ mintedStore: AppStore; shouldHydrate: boolean }> {
    if (!token) {
        // return a clean slate
        return { mintedStore: setupStore(), shouldHydrate: false };
    }

    const store = setupStore({
        auth: { token, claims: null, user: null },
    });

    try {
        const claims = await store
            .dispatch(authService.endpoints.verifyAuthToken.initiate(token))
            .unwrap();

        const isExpired = isTokenExpired(claims.exp);

        if (isExpired) {
            throw new Error('Token expired');
        }

        store.dispatch(
            setClaims({
                ...claims,
            })
        );

        return {
            mintedStore: store,
            shouldHydrate: true,
        };
    } catch (error) {
        // return a clean slate
        return { mintedStore: setupStore(), shouldHydrate: false };
    }
}

/**
 * Prevent the app from loading until we determine if we have a valid token and
 * fetch all our initial state data.
 */
export default function HydrationGate({ children }: HydrationGateProps) {
    const [canMount, setCanMount] = useState<boolean>(false);
    const { token } = useGlobalAuth();

    const hydrateCurrentUser = useCallback(async (store: AppStore) => {
        try {
            const user = await store.dispatch(authService.endpoints.me.initiate()).unwrap();
            store.dispatch(setCurrentUser(user));

            return Promise.resolve();
        } catch (error) {
            /**
             * Just swallow the error for now. When the app mounts it will
             * redirect to the /login route without a user
             */
            return Promise.reject();
        }
    }, []);

    const hydrateStore = useCallback(
        (store: AppStore) => {
            Promise.allSettled([hydrateCurrentUser(store)]).finally(() => {
                setCanMount(true);
            });
        },
        [hydrateCurrentUser]
    );

    const initializeStore = useCallback(
        async (token?: string) => {
            const { mintedStore, shouldHydrate } = await createStore(token);
            store = mintedStore;

            // required for refetchOnFocus/refetchOnReconnect behaviors
            setupListeners(store.dispatch);

            if (shouldHydrate) {
                hydrateStore(store);
            } else {
                setCanMount(true);
            }
        },
        [hydrateStore]
    );

    useEffect(() => {
        initializeStore(token);

        // Only once on mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    if (!canMount || store === null) {
        return <FullScreenFallbackLayout isLoading />;
    }

    return <ReduxProvider store={store}>{children}</ReduxProvider>;
}
