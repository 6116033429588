import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import {
    RemoveColumnFilterAction,
    SetAllColumnFiltersAction,
    SetGlobalFilterAction,
    TABLES,
    TableState,
    UpsertColumnFilterAction,
} from './types';

export const emptyState: TableState = {
    sort: undefined,
    globalFilter: undefined,
    columnFilters: undefined,
};

export const initialTableState: Record<TABLES, TableState> = {
    INVOICES: {
        ...emptyState,
    },
    ASSETS: {
        ...emptyState,
    },
};

export const tableSlice = createSlice({
    name: 'table',
    initialState: initialTableState,
    reducers: {
        clearAllTablesState: () => {
            return initialTableState;
        },
        setColumnFilters: (state, action: PayloadAction<SetAllColumnFiltersAction>) => {
            const { data, table } = action.payload;
            state[table].columnFilters = data;
        },
        upsertColumnFilter: (state, action: PayloadAction<UpsertColumnFilterAction>) => {
            const { data, table } = action.payload;
            const tableState = state[table];

            // If we don't have column filters yet, initialize them.
            if (!tableState.columnFilters) {
                tableState.columnFilters = [data];
                return;
            }

            const foundIndex = tableState.columnFilters.findIndex(({ id }) => id === data.id);

            // If we have a filter for the column id already, update it...
            if (foundIndex > -1) {
                tableState.columnFilters[foundIndex] = data;
                return;
            }

            // ...if not, add it
            tableState.columnFilters.push(data);
        },
        removeColumnFilter: (state, action: PayloadAction<RemoveColumnFilterAction>) => {
            const { id, table } = action.payload;
            const tableState = state[table];

            // If we don't have column filters yet, then we have nothing to do.
            if (!tableState.columnFilters) {
                return state;
            }

            // Cool name eh? ;)
            const filteredFilters = tableState.columnFilters.filter(
                ({ id: columnId }) => columnId !== id
            );
            tableState.columnFilters = filteredFilters;
        },
        setGlobalFilter: (state, action: PayloadAction<SetGlobalFilterAction>) => {
            const { data, table } = action.payload;
            state[table].globalFilter = data;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    clearAllTablesState,
    removeColumnFilter,
    setColumnFilters,
    setGlobalFilter,
    upsertColumnFilter,
} = tableSlice.actions;

export default tableSlice.reducer;
