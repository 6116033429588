import CreateOffer from 'components/Application/ApplicationDetail/Offers/CreateOffer/CreateOffer';
import InternalLink from 'components/InternalLink';
import FullScreenFallbackLayout from 'components/layout/FullScreenFallbackLayout';
import Whoops from 'components/Whoops';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { URLS } from 'routes/urls';

export default function CreateOfferPage() {
    const { id, userId } = useTypedParams(URLS.Applications.Detail.Offers.Create);

    if (!id || !userId) {
        return (
            <FullScreenFallbackLayout>
                <Whoops message="An error occurred while loading this offer">
                    Invalid URL values for application ID or user ID provided.{' '}
                    <InternalLink to={URLS.Applications.buildPath({})}>
                        Return to the applications tab.
                    </InternalLink>
                </Whoops>
            </FullScreenFallbackLayout>
        );
    }

    return <CreateOffer id={id} userId={userId} />;
}
