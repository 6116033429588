import { useContext } from 'react';

import SideMenuContext from '../context/SideMenuContext';

export default function useSideMenu() {
    const context = useContext(SideMenuContext);

    if (!context) {
        throw new Error('useSideMenu must be used inside the SideMenu Context');
    }

    return context;
}
