import { RejectedFile } from '../FileDropArea/types';
import FileList, { FileListProps } from './FileList';
import FileListFile from './FileListFile';

export type RejectedListProps = Pick<
    FileListProps<RejectedFile>,
    'onClear' | 'onRemoveFile' | 'files'
>;

export default function RejectedList({ files = [], onRemoveFile, onClear }: RejectedListProps) {
    return (
        <FileList<RejectedFile>
            className="file-list--rejected"
            files={files}
            onClear={onClear}
            onRemoveFile={onRemoveFile}
            title="Rejected Files"
        >
            {(files, onRemoveFile) =>
                files.map((rejection) => {
                    const { errors, file, key } = rejection;
                    const { name } = file;
                    const [firstError] = errors;

                    return (
                        <FileListFile
                            aria-label={`${name} - ${firstError.message}`}
                            handleRemove={() => onRemoveFile(rejection)}
                            key={key}
                            label={`${name} - ${firstError.message}`}
                        />
                    );
                })
            }
        </FileList>
    );
}
