import { IconProps } from './types';

export default function GearIcon({ height, width, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 16 18"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M7.96 11.68a2.7 2.7 0 1 1 2.67-2.7 2.68 2.68 0 0 1-2.67 2.7Zm0-3.6a.9.9 0 1 0 .89.9.9.9 0 0 0-.89-.9Z"
                fill="currentColor"
            />
            <path
                d="M8 18a2.57 2.57 0 0 1-.67-.09 2.5 2.5 0 0 1-1.69-1.72l-.53-1.72a.28.28 0 0 0-.35-.2l-1.73.41a2.46 2.46 0 0 1-1.87-.32 2.53 2.53 0 0 1-.5-3.84L1.84 9.2a.32.32 0 0 0 0-.42L.64 7.44A2.54 2.54 0 0 1 .78 3.9a2.43 2.43 0 0 1 2.24-.61l1.74.41a.31.31 0 0 0 .34-.21l.52-1.74a2.44 2.44 0 0 1 4.68 0l.57 1.74a.29.29 0 0 0 .35.21l1.73-.4a2.47 2.47 0 0 1 3 1.88 2.53 2.53 0 0 1-.66 2.29l-1.17 1.32a.3.3 0 0 0 0 .42l1.19 1.34a2.53 2.53 0 0 1-.13 3.53 2.47 2.47 0 0 1-2.24.62l-1.75-.41a.31.31 0 0 0-.34.2l-.53 1.75a2.45 2.45 0 0 1-1.16 1.47A2.4 2.4 0 0 1 8 18Zm-3.17-5.53a2.07 2.07 0 0 1 2 1.48l.53 1.74a.69.69 0 0 0 .47.49.67.67 0 0 0 .52-.06.7.7 0 0 0 .32-.41l.54-1.76a2 2 0 0 1 2.42-1.43l1.75.41a.71.71 0 0 0 .64-.17.77.77 0 0 0 .22-.5.68.68 0 0 0-.19-.5l-1.19-1.35a2.13 2.13 0 0 1 0-2.85l1.18-1.32a.72.72 0 0 0 .18-.65.71.71 0 0 0-.84-.54l-1.74.41a2.05 2.05 0 0 1-2.43-1.42l-.57-1.73a.69.69 0 0 0-.45-.46.67.67 0 0 0-.84.46l-.52 1.73A2.05 2.05 0 0 1 4.4 5.47l-1.75-.41a.7.7 0 0 0-.63.18.72.72 0 0 0 0 1l1.2 1.34a2.12 2.12 0 0 1 0 2.85l-1.18 1.32a.72.72 0 0 0 .13 1.1.71.71 0 0 0 .53.09l1.74-.41a2.49 2.49 0 0 1 .39-.06Z"
                fill="currentColor"
            />
        </svg>
    );
}
