import InternalLink from 'components/InternalLink';
import FullScreenFallbackLayout from 'components/layout/FullScreenFallbackLayout';
import PageLayout from 'components/layout/PageLayout';
import Whoops from 'components/Whoops';
import { useNavigate } from 'react-router-dom';
import { useGetUserApplicationByIdQuery } from 'redux/modules/application';
import { useGetAssetQuery } from 'redux/modules/asset';
import { URLS } from 'routes/urls';

import OfferForm from '../../Tabs/ApproveFirmTab/OfferSection/OfferForm';
import Header from '../Header';

interface OffersCreateProps {
    id: string;
    userId: string;
}

export default function CreateOffer({ id, userId }: OffersCreateProps) {
    const navigate = useNavigate();

    const { data: application } = useGetUserApplicationByIdQuery({
        applicationId: id,
        userId,
    });

    const { data: asset, isLoading } = useGetAssetQuery(application?.building_suite_id ?? '', {
        skip: !application?.building_suite_id,
    });

    if (!asset || isLoading) {
        return (
            <FullScreenFallbackLayout isLoading={isLoading}>
                <Whoops message="No building suite found for applicant.">
                    A placeholder building suite must be present for an applicant in order to create
                    an offer. To create a placeholder building suite, please approve the application
                    for a home budget.{' '}
                    <InternalLink
                        to={URLS.Applications.Detail.buildPath(
                            { id, userId },
                            { tab: 'approve-firm' }
                        )}
                    >
                        Return to the approval tab.
                    </InternalLink>
                </Whoops>
            </FullScreenFallbackLayout>
        );
    }

    return (
        <PageLayout header={<Header applicationId={id} userId={userId} />}>
            <OfferForm
                asset={asset}
                onSuccessfulSubmit={() =>
                    navigate(
                        URLS.Applications.Detail.buildPath({ id, userId }, { tab: 'approve-firm' })
                    )
                }
            />
        </PageLayout>
    );
}
