import { IconProps } from './types';

export default function PencilIcon({ height, width, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 25.5 25.5"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M1.5 25.5a1.5 1.5 0 0 1-1.42-2l2.15-6.44a1.46 1.46 0 0 1 .36-.58L18.62.44a1.52 1.52 0 0 1 2.13 0l4.29 4.29a1.51 1.51 0 0 1 0 2.12L8.97 22.92a1.57 1.57 0 0 1-.59.36l-6.43 2.14a1.34 1.34 0 0 1-.45.08ZM16.16 5.05 3.62 17.57l-2.11 6.48 6.43-2.15L20.52 9.32Zm5.43 4.22Zm-4.37-5.28 4.36 4.22L24 5.79 19.71 1.5Z"
                fill="currentColor"
            />
        </svg>
    );
}
