import { useEffect, useMemo, useRef } from 'react';

/**
 * Call a function only once (last call) if called multiple times in timeout window
 *
 * @src https://gist.github.com/ca0v/73a31f57b397606c9813472f7493a940?permalink_comment_id=4276799#gistcomment-4276799
 *
 * @param func Function you would like to call
 * @param waitFor Timeout in ms
 */
export function debounce<F extends (...args: Parameters<F>) => ReturnType<F>>(
    func: F,
    waitFor: number
) {
    let timeout: ReturnType<typeof setTimeout>;

    const debounced = (...args: Parameters<F>) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), waitFor);
    };

    return debounced;
}

/**
 * Debounce hook that allows passing a closure with most recent state and
 * avoid re-rendering by setting ref.current so debouncedClosure only
 * gets created once but closure gets updated in memory.
 *
 * @src https://www.developerway.com/posts/debouncing-in-react
 *
 * @param closure
 * @returns
 */
function useDebounce<Func extends (...args: any[]) => void>(closure: Func) {
    const ref = useRef<Func>();

    useEffect(() => {
        ref.current = closure;
    }, [closure]);

    // created only once - on mount
    const debouncedClosure = useMemo(() => {
        const func = () => {
            // ref is mutable! ref.current is a reference to the latest closure
            ref.current?.();
        };

        // the func that was created once, but has access to the latest closure
        return debounce(func, 1_000);

        // no dependencies! never gets updated
    }, []);

    return debouncedClosure;
}

export default useDebounce;
