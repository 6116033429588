import { FocusEventHandler, ReactNode } from 'react';
import ReactSelect, {
    ActionMeta,
    GroupBase,
    MultiValue,
    OptionsOrGroups,
    SingleValue as SingleValueType,
} from 'react-select';

import InputGroup, { InputGroupProps } from '../InputGroup';
import { SELECT_PLACEHOLDER } from '../Select/constants';
import { STYLES } from './constants';
import classes from './SearchableSelect.module.scss';
import { customComponents } from './SelectComponents';

export interface SearchableSelectOption {
    label: string;
    value: string;
}

type SingleOrArray<T> = T extends true ? Array<SearchableSelectOption> : SearchableSelectOption;

type OnChangeValue<IsMulti extends boolean> = IsMulti extends true
    ? SearchableSelectOption[] | null
    : SearchableSelectOption | null;

export interface CommonProps<IsMulti extends boolean>
    extends Omit<InputGroupProps, 'children' | 'className'> {
    /** Should an `x` button be displayed to clear the input when a value is present */
    clearable?: boolean;
    /** Default value of the select */
    defaultValue?: SingleOrArray<IsMulti>;
    /** Is the input disabled */
    disabled?: boolean;
    /** Force if the dropdown of options is open */
    menuIsOpen?: boolean;
    /** Text to display when there are no options */
    noOptionsMessage?: (obj: { inputValue: string }) => ReactNode;
    /** Function to be called on the onBlur event */
    onBlur?: FocusEventHandler<HTMLInputElement>;
    /** Function to be called on the onChange event [@src](https://react-select.com/advanced#action-meta) to see possible values for Action Meta */
    onChange: (
        newValue: OnChangeValue<IsMulti>,
        actionMeta: ActionMeta<SearchableSelectOption>
    ) => void;
    /** Handle focus events on the control */
    onFocus?: FocusEventHandler<HTMLInputElement>;
    /** Array of selectable options */
    options: OptionsOrGroups<SearchableSelectOption, GroupBase<SearchableSelectOption>>;
    /** Placeholder text of the input */
    placeholder?: string;
    /** Continue to show selected options in the dropdown menu */
    showSelectedOptions?: boolean;
    /** Specify force value of select */
    value?: SingleOrArray<IsMulti>;
}

export interface SingleSearchableSelectProps extends CommonProps<false> {
    /** Is the user able to select multiple values */
    isMulti?: false;
}

export interface MultiSearchableSelectProps extends CommonProps<true> {
    isMulti: true;
}

export type SearchableSelectProps = SingleSearchableSelectProps | MultiSearchableSelectProps;

export default function SearchableSelect({
    caption,
    clearable = false,
    defaultValue,
    disabled = false,
    errors,
    hideLabel,
    label,
    name,
    onBlur,
    onChange,
    options,
    placeholder = SELECT_PLACEHOLDER,
    required = false,
    secondaryLabel,
    isMulti = false,
    value,
    menuIsOpen,
    showSelectedOptions,
    onFocus,
    noOptionsMessage = () => 'No Options',
}: SearchableSelectProps) {
    function handleChange(
        newValue: MultiValue<SearchableSelectOption> | SingleValueType<SearchableSelectOption>,
        actionMeta: ActionMeta<SearchableSelectOption>
    ) {
        /**
         * A multi select will return an empty array if no selections. To be consistent with the
         * single select, we should return null for no selections.
         */
        if (Array.isArray(newValue) && newValue.length < 1) {
            onChange(null, actionMeta);
            return;
        }

        // TODO: How to make typescript happy with compatible types
        // @ts-ignore
        onChange(newValue, actionMeta);
    }

    return (
        <InputGroup
            caption={caption}
            errors={errors}
            hideLabel={hideLabel}
            label={label}
            name={name}
            required={required}
            secondaryLabel={secondaryLabel}
        >
            {({ errorId, isInvalid, labelId }) => (
                <ReactSelect
                    aria-errormessage={errorId}
                    aria-invalid={isInvalid}
                    aria-labelledby={labelId}
                    className={classes.select}
                    classNamePrefix={classes.select}
                    components={customComponents}
                    defaultValue={defaultValue}
                    hideSelectedOptions={!showSelectedOptions}
                    isClearable={clearable}
                    isDisabled={disabled}
                    isInvalid={isInvalid}
                    isMulti={isMulti}
                    isSearchable
                    menuIsOpen={menuIsOpen}
                    menuPlacement="auto"
                    menuPortalTarget={document.body}
                    name={name}
                    noOptionsMessage={noOptionsMessage}
                    onBlur={onBlur}
                    onChange={handleChange}
                    onFocus={onFocus}
                    options={options}
                    placeholder={placeholder}
                    styles={STYLES}
                    value={value}
                />
            )}
        </InputGroup>
    );
}
