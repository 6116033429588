import { Offer } from '@keyliving/shared-types';

export function getFormattedAddressFromOffer(offer: Offer | undefined | null): string | undefined {
    const sections: string[] = [];

    if (!offer) {
        return undefined;
    }

    if (offer.building_unit) {
        sections.push(`${offer.building_unit} - `);
    }

    sections.push(
        `${offer.building_street} ${offer.building_city}, ${offer.building_region} ${offer.building_postal_code}`
    );

    return sections.join('');
}
