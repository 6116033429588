export default function IconExpandLeft() {
    return (
        <svg
            height={24}
            viewBox="0 0 24 24"
            width={24}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
        >
            <path
                d="M13.72 6a.86.86 0 0 1 .6 1.47L9.79 12l4.53 4.54a.86.86 0 0 1-1.21 1.21l-5.14-5.14a.86.86 0 0 1 0-1.21l5.14-5.15a.9.9 0 0 1 .61-.25Z"
                fill="currentColor"
            />
        </svg>
    );
}
