import { useMemo } from 'react';

import range from '../lib/range';

export interface UsePaginationProps {
    /** Current page number */
    currentPage: number;
    /** Number of nav items on either side of the current page before adding ... */
    siblingCount?: number;
    /** Number of available pages */
    totalNumPages: number;
}

/**
 * Adapted from blog article
 *
 * @src https://www.freecodecamp.org/news/build-a-custom-pagination-component-in-react/
 */
export default function usePagination({
    currentPage,
    siblingCount = 1,
    totalNumPages,
}: UsePaginationProps) {
    const paginationRange = useMemo<Array<number | null>>(() => {
        // Total Nav Items is determined as 2*siblingCount + firstPage + lastPage + currentPage + 2*Ellipsis
        const minNavItemCount = siblingCount * 2 + 5;

        // (First | Last) item + current item + ellipsis + 2*siblingCount
        const minEdgeItems = siblingCount * 2 + 3;

        // If the number of pages is less than the required number of pages we need to show our formatting just return all pages.
        if (totalNumPages < minNavItemCount) {
            return range({ start: 1, stop: totalNumPages });
        }

        const leftSiblingPage = Math.max(currentPage - siblingCount, 1);
        const rightSiblingPage = Math.min(currentPage + siblingCount, totalNumPages);

        // We do not want to show an ellipsis if there is only one position left
        const showLeftEllipsis = leftSiblingPage > 2;
        const showRightEllipsis = rightSiblingPage < totalNumPages - 2;

        if (!showLeftEllipsis && showRightEllipsis) {
            const leftRange = range({ start: 1, stop: minEdgeItems });

            return [...leftRange, null, totalNumPages];
        }

        if (showLeftEllipsis && !showRightEllipsis) {
            const rightRange = range({
                start: totalNumPages - minEdgeItems + 1,
                stop: totalNumPages,
            });

            return [1, null, ...rightRange];
        }

        return [
            1,
            null,
            ...range({ start: leftSiblingPage, stop: rightSiblingPage }),
            null,
            totalNumPages,
        ];
    }, [currentPage, siblingCount, totalNumPages]);

    return paginationRange;
}
