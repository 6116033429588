/**
 * Return a filename based on what was specified or fallback
 * to a resonable default.
 */
export function resolveFilename(filename: string = 'key-download') {
    const timestamp = Date.now();

    return `${filename}-${timestamp}.csv`;
}

/**
 * Simple safari detection based on user agent test
 *
 * @src - https://github.com/react-csv/react-csv/blob/master/src/core.js
 */
export const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

/**
 * Returns a Blob object from the passed CSV string.
 *
 * Accounts for different MIME type based on if using Safari.
 */
export function createBlobFromCsv(csv: string) {
    const type = isSafari() ? 'application/csv' : 'text/csv';

    return new Blob([csv], { type });
}

/**
 * Creates a url representation of the CSV
 *
 * This can then be passed to the download attribute to download the csv.
 * Inspiration taken from the below.
 *
 * @src https://github.com/react-csv/react-csv/blob/master/src/core.js
 */
export function buildURI(csv: string) {
    const blob = createBlobFromCsv(csv);
    const dataURI = `data:${blob.type};charset=utf-8,${csv}`;
    const URL = window.URL || window.webkitURL;

    return typeof URL.createObjectURL === 'undefined' ? dataURI : URL.createObjectURL(blob);
}
