import { useSideMenu } from '@keyliving/component-lib';
import classnames from 'classnames/bind';
import { ReactNode } from 'react';

import classes from './PageLayout.module.scss';

const cx = classnames.bind(classes);

interface PageProps {
    children: ReactNode;
    header?: ReactNode;
}

export default function PageLayout({ children, header }: PageProps) {
    const { menuExpanded, menuOpen } = useSideMenu();

    return (
        <>
            <div
                className={cx('header', {
                    header__title: typeof header === 'string',
                    'header--expanded': menuExpanded,
                })}
            >
                {header}
            </div>

            <div
                className={cx('content', {
                    'content--open': menuOpen,
                    'content--expanded': menuExpanded,
                })}
            >
                {children}
            </div>
        </>
    );
}
