interface RangeProps {
    start: number;
    step?: number;
    stop: number;
}

/**
 * Generate an array of numbers from the start number to the end number
 *
 * @src https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/from#sequence_generator_range
 *
 */
export default function range({ start, step = 1, stop }: RangeProps) {
    return Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
}
