import { IconProps } from './types';

export default function ParagraphIcon({ height, width }: IconProps) {
    return (
        <svg
            fill="none"
            height={height}
            viewBox="0 0 18 18"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_310_5168)">
                <path
                    d="M2.29688 1.26428H15.7031C15.7031 1.26428 16.7344 1.26428 16.7344 2.29553V15.7018C16.7344 15.7018 16.7344 16.733 15.7031 16.733H2.29688C2.29688 16.733 1.26562 16.733 1.26562 15.7018V2.29553C1.26562 2.29553 1.26562 1.26428 2.29688 1.26428Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.45312 4.35803H13.6406"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4.35938 10.5455H13.6406"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4.35938 7.45178H13.6406"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4.35938 13.6393H9.51562"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_310_5168">
                    <rect fill="transp" height="18" width="18" />
                </clipPath>
            </defs>
        </svg>
    );
}
