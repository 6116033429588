import { ChevronLeft, IconButton } from '@keyliving/component-lib';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import classes from './PageLayout.module.scss';

interface PageHeaderProps {
    backUrl?: string;
    canGoBack?: boolean;
    children?: ReactNode;
    title?: string;
}

export default function PageHeader({ backUrl, canGoBack, children, title }: PageHeaderProps) {
    const navigate = useNavigate();

    return (
        <div className={classes['page-header']}>
            {canGoBack ? (
                <IconButton
                    aria-label="Back"
                    className={classes['page-header__back']}
                    icon={<ChevronLeft />}
                    onClick={() => {
                        /**
                         * Specific url or just back one
                         * This "if" is silly but TS wouldn't accept backUrl ?? -1
                         * ¯\_(ツ)_/¯
                         */
                        if (backUrl) {
                            navigate(backUrl);
                            return;
                        }

                        navigate(-1);
                    }}
                    theme={null}
                />
            ) : null}
            {title ? <div className={classes['page-header__title']}>{title}</div> : null}
            {children}
        </div>
    );
}
