import { Badge, BadgeSize } from '@keyliving/component-lib';
import { OfferStatus } from '@keyliving/shared-types';
import classNames from 'classnames/bind';
import { useMemo } from 'react';

import classes from './OfferStatusBadge.module.scss';

const cx = classNames.bind(classes);

interface OfferBadgeProps {
    size: BadgeSize;
    status: OfferStatus | undefined;
}

export default function OfferBadge(
    { size, status }: OfferBadgeProps = { status: OfferStatus.PENDING, size: 'sm' }
) {
    const offerStatus = useMemo(() => {
        switch (status) {
            case OfferStatus.ACCEPTED:
                return 'Accepted';
            case OfferStatus.REJECTED:
                return 'Rejected';
            case OfferStatus.NOT_PROCEEDING:
                return 'Not proceeding';
            default:
                return 'Conditional';
        }
    }, [status]);

    return (
        <Badge
            className={cx(classes.offer_badge, {
                [classes['offer_badge--accepted']]: status === OfferStatus.ACCEPTED,
                [classes['offer_badge--rejected']]: status === OfferStatus.REJECTED,
                [classes['offer_badge--not_proceeding']]: status === OfferStatus.NOT_PROCEEDING,
            })}
            element="div"
            label={offerStatus}
            size={size}
        />
    );
}
