/**
 * Determine if the path in question is the active or is an ancestor
 * route of the current route.
 *
 * Logic taken from the React Router NavLink component.
 *
 * @src https://github.com/remix-run/react-router/blob/main/packages/react-router-dom/index.tsx#L303
 *
 * @param currentPath {string} the current pathname
 * @param toPath {string} the pathname in question
 */
export function isActiveRoute(currentPath: string, toPath: string) {
    // Exact path match
    if (currentPath === toPath) {
        return true;
    }

    // startsWith: If route is an ancestor
    // charAt: on a child route and not just the home page
    if (currentPath.startsWith(toPath) && currentPath.charAt(toPath.length) === '/') {
        return true;
    }

    return false;
}
