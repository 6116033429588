import { ToastProvider } from '@keyliving/component-lib';
import ErrorBoundary from 'components/ErrorBoundary';
import { StrictMode } from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';

render(
    <StrictMode>
        <BrowserRouter>
            <ErrorBoundary>
                <ToastProvider>
                    <App />
                </ToastProvider>
            </ErrorBoundary>
        </BrowserRouter>
    </StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
