import { IconProps } from './types';

export default function NoteIcon({ height, width }: IconProps) {
    return (
        <svg
            fill="none"
            height={height}
            viewBox="0 0 18 18"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_310_5174)">
                <path
                    d="M10.1199 16.7344H2.29688C2.02337 16.7344 1.76107 16.6257 1.56767 16.4323C1.37427 16.2389 1.26563 15.9766 1.26562 15.7031V2.29688C1.26562 2.02337 1.37427 1.76107 1.56767 1.56767C1.76107 1.37427 2.02337 1.26563 2.29688 1.26562H15.7031C15.9766 1.26563 16.2389 1.37427 16.4323 1.56767C16.6257 1.76107 16.7344 2.02337 16.7344 2.29688V10.1199C16.7343 10.3933 16.6258 10.6554 16.4326 10.8487L10.8487 16.4326C10.6554 16.6258 10.3933 16.7343 10.1199 16.7344Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.5469 16.6416V11.5781C10.5469 11.3046 10.6555 11.0423 10.8489 10.8489C11.0423 10.6555 11.3046 10.5469 11.5781 10.5469H16.6416"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_310_5174">
                    <rect fill="white" height="18" width="18" />
                </clipPath>
            </defs>
        </svg>
    );
}
