import { MouseEventHandler } from 'react';

import { CloseIcon, FileIcon } from '../../icons';
import IconButton from '../IconButton';
import classes from './FileUpload.module.scss';

export interface FileListFileProps {
    /** Label to announce to screen readers when file added to list */
    'aria-label': string;
    /** Handler for when a use clicks the "x" */
    handleRemove: MouseEventHandler<HTMLButtonElement>;
    /** Label to identify the file in the list */
    label: string;
}

export default function FileListFile({
    'aria-label': ariaLabel,
    handleRemove,
    label,
}: FileListFileProps) {
    return (
        <div aria-label={ariaLabel} aria-live="assertive" className={classes['file-list__file']}>
            <div className={classes['file-list__file-title']}>
                <FileIcon
                    aria-hidden
                    className={classes['file-list__file-icon']}
                    height={26}
                    width={22}
                />{' '}
                {label}
            </div>
            <IconButton
                aria-label={`Dismiss from list`}
                fill={null}
                icon={<CloseIcon />}
                onClick={handleRemove}
                rounded
                size="xs"
                theme={null}
            />
        </div>
    );
}
