import classNames from 'classnames/bind';
import { ReactNode } from 'react';

import classes from './PageHeaderSection.module.scss';

const cx = classNames.bind(classes);

interface PageHeaderSectionProps {
    grow?: boolean;
    subTitle?: ReactNode;
    title: ReactNode;
}

export default function PageHeaderSection({
    grow = false,
    subTitle,
    title,
}: PageHeaderSectionProps) {
    return (
        <div
            className={cx('page-header-section', {
                'page-header-section--grow': grow,
            })}
        >
            <div className={cx('page-header-section__title')}>{title}</div>
            {subTitle ? (
                <div className={cx('page-header-section__sub-title')}>{subTitle}</div>
            ) : null}
        </div>
    );
}
