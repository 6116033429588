import { route, string } from 'react-router-typesafe-routes/dom';

export const URLS = {
    Applications: route(
        'applications',
        {},
        {
            Detail: route(
                ':id/user/:userId',
                {
                    params: {
                        id: string(),
                        userId: string(),
                    },
                    searchParams: {
                        tab: string().default('info'),
                    },
                },
                {
                    Offers: route(
                        'offers',
                        {},
                        {
                            Create: route('create', {}),
                            Detail: route(':offerId', {
                                params: {
                                    offerId: string(),
                                },
                            }),
                        }
                    ),
                }
            ),
        }
    ),
    CapTables: route('cap-tables', {}),
    ContractConditions: route(
        'contract-conditions',
        {},
        {
            Create: route('create'),
        }
    ),
    Database: route(
        'database',
        {},
        {
            Assets: route(
                'assets',
                {},
                {
                    Detail: route(':id', {
                        searchParams: {
                            tab: string().default('about'),
                        },
                    }),
                    Create: route('create'),
                }
            ),
            Buildings: route(
                'buildings',
                {},
                {
                    Detail: route(':id', {
                        params: {
                            id: string(),
                        },
                        searchParams: {
                            tab: string().default('about'),
                        },
                    }),
                    Create: route('create'),
                }
            ),
            Owners: route('owners'),
            ReimbursementAdjustment: route('reimbursement-adjustment'),
        }
    ),
    Invoices: route(
        'invoices',
        {},
        {
            ByMonth: route(':year/:month', {
                params: {
                    year: string(),
                    month: string(),
                },
            }),
            Csv: route(
                'csv',
                {},
                {
                    ByMonth: route(':year/:month'),
                }
            ),
            LineItemsDenormalized: route(
                'line-items-denormalized',
                {},
                {
                    ByMonth: route(':year/:month'),
                }
            ),
            OwnerPayments: route(
                'owner-payments',
                {},
                {
                    ByMonth: route(':year/:month'),
                }
            ),
            Detail: route(':id', {
                searchParams: {
                    tab: string().default('about'),
                },
            }),
            Create: route('create'),
            MonthlyUpload: route('upload'),
        }
    ),
    Products: route(
        'products',
        {},
        {
            Create: route('create'),
        }
    ),
    Profile: route('profile'),
    Users: route(
        'users',
        {},
        {
            Detail: route(':id', {
                searchParams: {
                    tab: string().default('info'),
                    folder: string(),
                },
            }),
            Create: route('create'),
        }
    ),
    Utility: route(
        'utility',
        {},
        {
            UnderwritingURLCreator: route('underwriting-url-creator', {}),
            ReferenceExperiment: route('reference-experiment', {}),
            MessageQueueAdmin: route('message-queue-admin', {}),
            TasksScheduledAcknowledgedAdmin: route('tasks-scheduled-acknowledged-admin', {}),
        }
    ),
};
