import { IconProps } from './types';

export default function DownloadIcon({ height, width, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 18 17.38"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M14.25 4.41h-2a.75.75 0 0 0 0 1.5h2a2.25 2.25 0 0 1 2.25 2.25v5.47a2.25 2.25 0 0 1-2.25 2.25H3.75a2.25 2.25 0 0 1-2.25-2.25V8.12a2.25 2.25 0 0 1 2.25-2.25h2a.75.75 0 0 0 0-1.5h-2A3.75 3.75 0 0 0 0 8.12v5.47a3.75 3.75 0 0 0 3.75 3.75h10.5A3.74 3.74 0 0 0 18 13.59V8.12a3.74 3.74 0 0 0-3.75-3.71Z"
                fill="currentColor"
            />
            <path
                d="m5.98 10.12 1.75 1.86a1.73 1.73 0 0 0 2.55 0l1.75-1.86a.75.75 0 0 0 0-1.06.75.75 0 0 0-1.06 0l-1.18 1.26V.75a.75.75 0 0 0-1.5 0v9.57L7.13 9.06a.75.75 0 0 0-1.1 1Z"
                fill="currentColor"
            />
        </svg>
    );
}
