import { Button, ConfigurableTable, TrashOutlineIcon } from '@keyliving/component-lib';
import {
    CONTRACT_CONDITION_FORMULA,
    CONTRACT_CONDITION_TYPE,
    ContractCondition,
} from '@keyliving/shared-types';
import { formatContractValueByFormula } from '@keyliving/utils';
import { useMemo } from 'react';
import { CellProps, Column, SortingRule } from 'react-table';
import { useGetAllContractConditionsQuery } from 'redux/modules/contract';

import { OfferConditionFormInputs } from '../AddOfferConditionDrawer/AddOfferConditionDrawer';

type OfferContractTableEntry = {
    buildingSuiteContractConditionsId: string;
    description: string | null;
    explanation: string | null;
    formula: CONTRACT_CONDITION_FORMULA;
    type: CONTRACT_CONDITION_TYPE;
    value: number;
};

type OfferContractTableProps = {
    contracts: OfferConditionFormInputs[];
    onDelete: (buildingSuiteContractConditionsId: string) => void;
};

export default function OfferContractTable({ contracts, onDelete }: OfferContractTableProps) {
    const { data: contractConditions } = useGetAllContractConditionsQuery();

    // Merge contract condition details with contract form data
    const fullContracts = useMemo(() => {
        if (!contractConditions) {
            return [];
        }

        const contractConditionMap = contractConditions.reduce((acc, condition) => {
            acc[condition.id] = condition;
            return acc;
        }, {} as Record<string, ContractCondition>);

        return contracts.map((contract) => {
            const contractCondition =
                contractConditionMap[contract.buildingSuiteContractConditionsId];

            return {
                ...contract,
                type: contractCondition.type,
                formula: contractCondition.formula,
                description: contractCondition.description,
                explanation: contractCondition.explanation,
            };
        });
    }, [contracts, contractConditions]);

    const defaultSortBy = useMemo<SortingRule<OfferContractTableEntry>[]>(() => {
        return [
            {
                id: 'type',
                desc: false,
            },
        ];
    }, []);

    const columns = useMemo<Column<OfferContractTableEntry>[]>(
        () => [
            {
                Header: 'Type',
                accessor: 'type',
            },
            {
                Header: 'Label',
                accessor: 'description',
                Cell: ({ row, value }: CellProps<OfferContractTableEntry, string>) => {
                    return (
                        <>
                            <span className="fw-semibold">{value}</span> <br />{' '}
                            <span className="fs-200">{row.original.description}</span>
                        </>
                    );
                },
            },
            {
                Header: 'Explanation',
                accessor: (row) => row.explanation,
                fixedWidth: true,
                width: 300,
                wrap: true,
            },
            {
                Header: 'Value',
                accessor: 'value',
                Cell: ({ row, value }: CellProps<OfferContractTableEntry, number>) =>
                    formatContractValueByFormula(value, row.original.formula),
            },
            {
                Header: 'Actions',
                id: 'actions',
                accessor: (row) => row.type,
                Cell: ({ row }: CellProps<OfferContractTableEntry, string>) => {
                    return (
                        <Button
                            fill="outline"
                            icon={<TrashOutlineIcon />}
                            onClick={(e) => {
                                onDelete(row.original.buildingSuiteContractConditionsId);
                            }}
                            size="sm"
                            theme="destructive"
                            type="button"
                        >
                            Remove
                        </Button>
                    );
                },
            },
        ],
        [onDelete]
    );

    return (
        <>
            {fullContracts?.length > 0 && (
                <ConfigurableTable<OfferContractTableEntry>
                    columns={columns}
                    data={fullContracts}
                    defaultHiddenColumns={['type']}
                    defaultSortBy={defaultSortBy}
                    rowsPerPage={20}
                />
            )}
        </>
    );
}
