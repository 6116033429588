import { useSideMenu } from '@keyliving/component-lib';
import classnames from 'classnames/bind';

import classes from './Hamburger.module.scss';

const cx = classnames.bind(classes);

interface HamburgerProps {
    darkTheme?: boolean;
}

export default function Hamburger({ darkTheme }: HamburgerProps) {
    const { menuOpen, toggleMenuOpen } = useSideMenu();

    return (
        <button
            aria-controls="main-menu"
            aria-expanded={menuOpen}
            aria-haspopup
            aria-label="Menu Toggle"
            className={cx('toggle', {
                'toggle--dark': darkTheme,
            })}
            onClick={() => {
                toggleMenuOpen();
            }}
            type="button"
        >
            <div
                className={cx('hamburger', {
                    active: menuOpen,
                })}
            >
                <div className={cx('hamburger__layer', 'hamburger__layer--one')} />
                <div className={cx('hamburger__layer', 'hamburger__layer--two')} />
                <div className={cx('hamburger__layer', 'hamburger__layer--three')} />
                <div className={cx('hamburger__layer', 'hamburger__layer--four')} />
            </div>
        </button>
    );
}
