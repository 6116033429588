import { IconProps } from './types';

export default function StockIcon({ height, width, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 24 14"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="m16.68.22 2.72 2.63s-5 5.6-5.81 5.6-3.27-4.29-4.76-4.59S0 12.38 0 12.38L1.68 14s6.15-6.89 7.15-6.89 3.56 4.26 4.76 4.59 7.5-7.22 7.5-7.22l2.73 2.63s.4-6.4 0-6.89-7.14 0-7.14 0Z"
                fill="currentColor"
            />
        </svg>
    );
}
