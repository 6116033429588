import { Asset, CONTRACT_CONDITION_TYPE } from '@keyliving/shared-types';

export function useApprovedHomeBudget(asset: Asset | undefined | null) {
    if (!asset) {
        return 0;
    }

    return (
        asset?.contracts?.find(
            (contract) => contract.type === CONTRACT_CONDITION_TYPE.HOME_SEARCH_BUDGET
        )?.value ?? 0
    );
}
