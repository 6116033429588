import UpdateOffer from 'components/Application/ApplicationDetail/Offers/UpdateOffer/UpdateOffer';
import InternalLink from 'components/InternalLink';
import FullScreenFallbackLayout from 'components/layout/FullScreenFallbackLayout';
import Whoops from 'components/Whoops';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { URLS } from 'routes/urls';

export default function UpdateOfferPage() {
    const { id, offerId, userId } = useTypedParams(URLS.Applications.Detail.Offers.Detail);

    if (!id || !userId || !offerId) {
        return (
            <FullScreenFallbackLayout>
                <Whoops message="An error occurred while loading this offer">
                    Invalid URL values for application ID, user ID or offer ID were provided.{' '}
                    <InternalLink to={URLS.Applications.buildPath({})}>
                        Return to the applications tab.
                    </InternalLink>
                </Whoops>
            </FullScreenFallbackLayout>
        );
    }

    return <UpdateOffer id={id} offerId={offerId} userId={userId} />;
}
