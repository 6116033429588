import { IconProps } from './types';

export default function UploadIcon({ height, width, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 18 16.94"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M14.25 4.6H12.3a.75.75 0 0 0 0 1.5h1.95a2.25 2.25 0 0 1 2.25 2.25v4.86a2.25 2.25 0 0 1-2.25 2.25H3.75a2.25 2.25 0 0 1-2.25-2.25V8.35A2.24 2.24 0 0 1 3.75 6.1h2a.75.75 0 0 0 0-1.5h-2A3.75 3.75 0 0 0 0 8.35v4.86a3.75 3.75 0 0 0 3.75 3.75h10.5A3.75 3.75 0 0 0 18 13.21V8.35a3.75 3.75 0 0 0-3.75-3.75Z"
                fill="currentColor"
            />
            <path
                d="M6.52 3.6a.79.79 0 0 0 .54-.22l1.19-1.21v9a.75.75 0 1 0 1.5 0v-9l1.19 1.21a.753.753 0 0 0 1.07-1.06L10.24.54a1.77 1.77 0 0 0-2.49 0L5.99 2.32a.75.75 0 0 0 0 1.06.7.7 0 0 0 .53.22Z"
                fill="currentColor"
            />
        </svg>
    );
}
