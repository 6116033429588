import { Button } from '@keyliving/component-lib';
import { ReactNode } from 'react';

interface SubmitButtonProps {
    children?: ReactNode;
    disabled?: boolean;
    loading?: boolean;
}

export default function SubmitButton({
    children = 'Submit',
    disabled = false,
    loading = false,
}: SubmitButtonProps) {
    return (
        <Button
            disabled={loading || disabled}
            isLoading={loading}
            size="md"
            theme="tertiary"
            type="submit"
        >
            {children}
        </Button>
    );
}
