import { IconProps } from './types';

export default function MoneyIcon({ height, width, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 25.5 25.5"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M12.8 8.86c1 0 2 .37 2 1.09a.75.75 0 1 0 1.5 0c0-1.33-1.11-2.32-2.77-2.54V5.34a.75.75 0 0 0-1.5 0v2c-1.34.06-2.77.41-2.77 2.58 0 1.85 1.69 2.7 3.18 3.44s2.36 1.24 2.36 2.1-.23 1.1-2 1.1c-2 0-2-.8-2-1.1a.75.75 0 1 0-1.5 0c0 .72.29 2.29 2.77 2.56v2.06a.75.75 0 0 0 1.5 0v-2c1.34-.06 2.77-.41 2.77-2.59 0-1.85-1.69-2.69-3.19-3.44s-2.35-1.24-2.35-2.1.16-1.09 2-1.09Z"
                fill="currentColor"
            />
            <path
                d="M12.8 0a12.75 12.75 0 1 0 12.75 12.75A12.76 12.76 0 0 0 12.8 0Zm0 24a11.25 11.25 0 1 1 11.25-11.25A11.26 11.26 0 0 1 12.8 24Z"
                fill="currentColor"
            />
        </svg>
    );
}
