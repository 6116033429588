/**
 * Converts the input value to a integer or null
 * in the case of an empty string.
 */
import { ZodErrorMap, ZodIssueCode } from 'zod';

export function intOrNull(val: unknown): number | null | unknown {
    if (typeof val === 'string') {
        return val.length > 0 ? parseInt(val, 10) : null;
    }

    return val;
}

/**
 * Converts the input value to a number or null
 * in the case of an empty string.
 */
export function numberOrNull(val: unknown): number | null | unknown {
    if (typeof val === 'string') {
        return val.length > 0 ? Number(val) : null;
    }

    return val;
}

/**
 * Checks if the input value is a string and either returns it
 * or null in the case of an empty string.
 */
export function stringOrNull(val: unknown): string | null | unknown {
    if (typeof val === 'string') {
        return val.length > 0 ? val : null;
    }

    return val;
}

/**
 * Checks if the input value is a string and compares to our common use case
 * of using yes/no, true/false and either returns a boolean or null in
 * the case of an empty string.
 */
export function booleanOrNull(val: unknown): boolean | null | unknown {
    if (typeof val === 'string') {
        if (val.length === 0) {
            return null;
        }

        if (val.toLowerCase() === 'yes' || val.toLowerCase() === 'true') {
            return true;
        }

        if (val.toLowerCase() === 'no' || val.toLowerCase() === 'false') {
            return false;
        }
    }

    return val;
}

/**
 * Error messages for enums are ugly and not helpful. This provides
 * a more user friendly message and falls back
 * to the default.
 */
export const enumErrorMap: ZodErrorMap = (issue, ctx) => {
    if (issue.code === ZodIssueCode.invalid_enum_value) {
        return { message: 'Invalid value' };
    }

    if (issue.code === ZodIssueCode.invalid_type) {
        return { message: 'Invalid value' };
    }

    return { message: ctx.defaultError };
};
