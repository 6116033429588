import classNames from 'classnames/bind';
import { Link, LinkProps } from 'react-router-dom';

import classes from './InternalLink.module.scss';

const cx = classNames.bind(classes);

export default function InternalLink({ className, ...rest }: LinkProps) {
    return <Link {...rest} className={cx('internal-link', className)} />;
}
