import { IconProps } from './types';

export default function ClipboardIcon({ height = 24, width = 24, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M20.25 8.249v-3a1.5 1.5 0 0 0-1.5-1.5h-4M6.25 3.749h-4a1.5 1.5 0 0 0-1.5 1.5v16.5a1.5 1.5 0 0 0 1.5 1.5h6"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                d="M14.421 4.736a.751.751 0 0 1-.712.513H7.291a.751.751 0 0 1-.712-.513l-1-3a.749.749 0 0 1 .712-.987h8.418a.749.749 0 0 1 .712.987Z"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <rect
                fill="none"
                height="12"
                rx="1.5"
                ry="1.5"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                width="12"
                x="11.25"
                y="11.249"
            />
            <path
                d="M14.25 14.249h6M14.25 17.249h6M14.25 20.249h2.25"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </svg>
    );
}
