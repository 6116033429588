import { IconProps } from './types';

export default function DocumentStampIcon({ height = 24, width = 24, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M23.78 6.08 17.97.22a.75.75 0 0 0-.53-.22h-9.7c-.96 0-1.75.78-1.75 1.75v4.27c0 .41.34.75.75.75s.75-.34.75-.75V1.75c0-.14.11-.25.25-.25h8.74v4.27c0 .96.78 1.75 1.75 1.75h4.27v14.75c0 .14-.11.25-.25.25h-6.52c-.41 0-.75.34-.75.75s.34.75.75.75h6.52c.96 0 1.75-.78 1.75-1.75V6.61c0-.2-.08-.39-.22-.53Zm-5.8-.32V2.35l3.62 3.66h-3.37c-.14 0-.25-.11-.25-.25Z"
                fill="currentColor"
            />
            <path
                d="M19.78 11.26h-7.04c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h7.04c.41 0 .75.34.75.75s-.34.75-.75.75ZM19.78 19.25h-2.8c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h2.8c.41 0 .75.34.75.75s-.34.75-.75.75ZM19.78 15.25h-5.54c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h5.54c.41 0 .75.34.75.75s-.34.75-.75.75ZM13.48 20.25v-.13c-.07-2.01-1.71-3.62-3.73-3.62H9v-1.52a3.766 3.766 0 0 0 1-4.87c-.5-.87-1.31-1.49-2.27-1.74-.97-.26-1.98-.13-2.84.37-.87.5-1.49 1.31-1.75 2.27-.26.97-.13 1.98.37 2.84.25.44.59.82 1 1.12v1.52h-.75a3.76 3.76 0 0 0-3.75 3.63v.13c0 .04.01.06.02.09v.09l.75 3c.08.33.38.57.73.57H12c.34 0 .64-.23.73-.57l.75-3v-.09c0-.03.02-.06.02-.09ZM3.74 18h1.5c.41 0 .75-.34.75-.75v-2.67c0-.27-.14-.51-.37-.65-.34-.2-.63-.48-.82-.82-.3-.52-.38-1.13-.22-1.7.16-.58.53-1.06 1.05-1.36s1.13-.38 1.7-.22a2.252 2.252 0 0 1 .54 4.12c-.23.13-.37.38-.37.65v2.67c0 .41.34.75.75.75h1.5c.98 0 1.81.63 2.12 1.5H1.63c.31-.87 1.14-1.5 2.12-1.5Zm-1.66 4.49-.37-1.5h10.07l-.37 1.5H2.08Z"
                fill="currentColor"
            />
        </svg>
    );
}
