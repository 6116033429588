import { Button, ChevronDown, ChevronUp } from '@keyliving/component-lib';
import { OfferStatus } from '@keyliving/shared-types';
import classNames from 'classnames/bind';
import { useCallback, useState } from 'react';

import classes from './UpdateOfferAndStatusButton.module.scss';

const cx = classNames.bind(classes);

export interface UpdateOfferStatusOption {
    value: OfferStatus;
}

interface UpdateOfferAndStatusButtonProps {
    onOptionClick: (option: UpdateOfferStatusOption) => void;
}

export default function UpdateOfferAndStatusButton({
    onOptionClick,
}: UpdateOfferAndStatusButtonProps) {
    const [isOpen, setIsOpen] = useState(false);

    const onClick = useCallback(
        (option: UpdateOfferStatusOption) => {
            onOptionClick(option);
            setIsOpen(false);
        },
        [onOptionClick, setIsOpen]
    );

    return (
        <div className={classes.wrapper}>
            <div className={classes.mark_as_button}>
                <Button fill="outline" onClick={() => setIsOpen(!isOpen)} size="sm" theme="primary">
                    <div className={classes.mark_as_button_content}>
                        Update and mark as
                        {isOpen ? (
                            <ChevronUp height={20} width={20} />
                        ) : (
                            <ChevronDown height={20} width={20} />
                        )}
                    </div>
                </Button>
            </div>
            {isOpen && (
                <div className={classes.dropdown_container}>
                    <button
                        className={cx(classes.option, classes['option--accepted'])}
                        onClick={() => onClick({ value: OfferStatus.ACCEPTED })}
                    >
                        Accepted by seller
                    </button>
                    <button
                        className={cx(classes.option, classes['option--rejected'])}
                        onClick={() => onClick({ value: OfferStatus.REJECTED })}
                    >
                        Rejected by seller
                    </button>
                    <button
                        className={cx(classes.option, classes['option--not-proceeding'])}
                        onClick={() => onClick({ value: OfferStatus.NOT_PROCEEDING })}
                    >
                        Not proceeding by Key
                    </button>
                </div>
            )}
        </div>
    );
}
