import { IconProps } from './types';

export default function ExitIcon({ height, width, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 22.5 20.71"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M672.61 386.25H668a4.24 4.24 0 0 1-3-1.28 4.37 4.37 0 0 1-1.24-3.07v-12a4.37 4.37 0 0 1 1.24-3.07 4.18 4.18 0 0 1 3-1.28h4.66a4.18 4.18 0 0 1 3 1.28 4.36 4.36 0 0 1 1.23 3.07v1.2a.75.75 0 1 1-1.5 0v-1.2a2.93 2.93 0 0 0-.81-2 2.68 2.68 0 0 0-1.94-.82H668a2.7 2.7 0 0 0-1.94.82 2.93 2.93 0 0 0-.81 2v12a2.9 2.9 0 0 0 .81 2 2.67 2.67 0 0 0 1.94.83h4.66a2.67 2.67 0 0 0 1.94-.83 2.9 2.9 0 0 0 .81-2v-1.2a.75.75 0 1 1 1.5 0v1.2a4.36 4.36 0 0 1-1.23 3.07 4.26 4.26 0 0 1-3.07 1.28Zm8.17-4.8a.7.7 0 0 1-.52-.22.74.74 0 0 1-.23-.53.77.77 0 0 1 .21-.53l3.43-3.53h-14.56a.75.75 0 0 1 0-1.5h14.56l-3.43-3.53a.73.73 0 0 1-.21-.52.76.76 0 0 1 .23-.54.75.75 0 0 1 1.06 0l4.66 4.8a.66.66 0 0 1 .14.2.67.67 0 0 1 .06.28.64.64 0 0 1-.06.28.66.66 0 0 1-.14.2l-4.66 4.8a.76.76 0 0 1-.54.34Z"
                fill="currentColor"
                transform="translate(-663.7 -365.54)"
            />
        </svg>
    );
}
