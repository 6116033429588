import { useGlobalAuth } from '@keyliving/component-lib';
import { clearAuthDetails, selectAuthData } from 'redux/modules/auth';

import { useAppDispatch, useAppSelector } from './redux';

export default function useAuth() {
    const { claims, token, user } = useAppSelector(selectAuthData);
    const dispatch = useAppDispatch();
    const { deleteToken } = useGlobalAuth();

    const signOut = () => {
        dispatch(clearAuthDetails());
        deleteToken();
        return Promise.resolve();
    };

    return {
        user,
        claims,
        token,
        isLoggedIn: !!user,
        signOut,
    };
}
