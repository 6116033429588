import { ChangeEventHandler, useMemo } from 'react';

import Select, { SelectOption } from '../../../Select';
import classes from './ResultsPerPageSelect.module.scss';

const DEFAULT_OPTIONS = [20, 50, 100, 200] as const;

export type NumRowsPerPage = typeof DEFAULT_OPTIONS[number];

export interface ResultsPerPageSelectProps {
    defaultValue: NumRowsPerPage;
    onChange: ChangeEventHandler<HTMLSelectElement>;
    totalNumRows: number;
}

export default function ResultsPerPageSelect({
    defaultValue,
    onChange,
    totalNumRows,
}: ResultsPerPageSelectProps) {
    const options = useMemo<SelectOption[]>(() => {
        return DEFAULT_OPTIONS.filter((option) => {
            // Filter out any options that are larger than the num rows we have
            return option <= totalNumRows;
        }).map((option) => ({
            label: `${option}`,
            value: `${option}`,
        }));
    }, [totalNumRows]);

    if (options.length < 2) {
        return null;
    }

    return (
        <div className={classes.wrapper}>
            <span aria-hidden>Rows per page:</span>
            <Select
                defaultEmpty={false}
                defaultValue={`${defaultValue}`}
                hideLabel
                label="Rows Per Page"
                name="resultsPerPage"
                onChange={onChange}
                options={options}
            />
        </div>
    );
}
