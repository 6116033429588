import { IconProps } from './types';

export default function ContractIcon({ height, width }: IconProps) {
    return (
        <svg
            fill="none"
            height={height}
            viewBox="0 0 18 18"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_310_5153)">
                <path
                    d="M7.05018 9.88899C7.33715 9.59251 7.72864 9.41994 8.14109 9.40812C8.55354 9.3963 8.95427 9.54617 9.25774 9.82574C9.5371 10.1292 9.68684 10.5298 9.67502 10.942C9.66321 11.3543 9.49077 11.7457 9.19449 12.0326C8.5888 12.6397 5.92749 13.1546 5.92749 13.1546C5.92749 13.1546 6.44449 10.4947 7.05018 9.88899Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9.50195 4.57788L12.0038 2.07607C12.0976 1.98221 12.209 1.90776 12.3316 1.85697C12.4543 1.80617 12.5857 1.78003 12.7184 1.78003C12.8511 1.78003 12.9826 1.80617 13.1052 1.85697C13.2278 1.90776 13.3392 1.98221 13.4331 2.07607L14.1481 2.79107"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.42944 8.5091L14.1481 2.79117C14.4329 2.50774 14.8184 2.34863 15.2202 2.34863C15.6221 2.34863 16.0075 2.50774 16.2924 2.79117C16.5755 3.07619 16.7343 3.4616 16.7343 3.86332C16.7343 4.26504 16.5755 4.65046 16.2924 4.93548L10.5738 10.6534L8.42944 8.5091Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.0037 4.93555L14.148 7.07986"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.3388 10.0312C15.3388 12.8093 15.631 14.3796 14.1301 15.8804L13.3519 16.658C13.3032 16.7061 13.2375 16.733 13.169 16.7329H1.52345C1.4725 16.7329 1.4227 16.7177 1.38034 16.6894C1.33799 16.6611 1.30498 16.6208 1.28548 16.5737C1.26599 16.5266 1.26088 16.4748 1.27081 16.4249C1.28074 16.3749 1.30526 16.329 1.34127 16.2929L1.75377 15.8804C3.31096 14.3232 2.96377 13.327 2.96377 5.03579C2.96377 2.87154 4.57802 1.71241 5.02627 1.26416H8.08427"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_310_5153">
                    <rect fill="white" height="18" width="18" />
                </clipPath>
            </defs>
        </svg>
    );
}
