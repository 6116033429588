import { IconProps } from './types';

export default function ChevronUp({ height, width, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 24 12.44"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M12.07 0a1.48 1.48 0 0 1 1 .44l10.78 10.72a.75.75 0 0 1-1.06 1.06L12.07 1.5 1.34 12.22a.77.77 0 0 1-1.07 0 .75.75 0 0 1 0-1.06L11.05.44A1.52 1.52 0 0 1 12.07 0Z"
                fill="currentColor"
            />
        </svg>
    );
}
