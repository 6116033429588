import { createElement } from 'react';

/**
 * Wrap the found string in a span with the given className
 *
 * @param needle {string} String to search for
 * @param haystack {string} The original string to search
 * @param className {string} ClassName to wrap the found string in
 */
export default function highlightFoundTermInString(
    needle: string,
    haystack: string,
    className: string
) {
    // if given an empty String, return the original string but as an array for a consistent return
    if (needle.length < 1) {
        return [haystack];
    }

    // Setup a RegEx to find the text in question
    const regex = new RegExp(`(${needle})`, 'gi');
    // Split the string into an array based on the RegEx
    const textArray = haystack.split(regex);

    return textArray.map((str) => {
        // If text item matches the RegEx
        if (regex.test(str)) {
            // Wrap it in a span
            return createElement(
                'span',
                {
                    className,
                },
                str
            );
        }

        // Otherwise just return the string
        return str;
    });
}
