export enum ENVIRONMENT {
    DEMO = 'demo',
    DEVELOPMENT = 'development',
    PRODUCTION = 'production',
    STAGING = 'staging',
}

export const determineEnvironment = () => {
    // typeof window !== 'undefined' is a check to handle running in a node environment for testing
    // it means that this function will always reeturn ENVIRONMENT.DEVELOPMENT in a test (i.e. node) environment
    const hostnameRaw = typeof window !== 'undefined' && window.location.hostname;

    // missing window object will occur during node test runs,
    // That is, test runs as ENVIRONMENT.DEVELOPMENT
    if (!hostnameRaw) {
        return ENVIRONMENT.DEVELOPMENT;
    }

    const hostname = hostnameRaw.toLowerCase();
    if (!hostname || hostname.includes('localhost')) {
        return ENVIRONMENT.DEVELOPMENT;
    }

    if (hostname.includes('demo')) {
        return ENVIRONMENT.DEMO;
    }

    if (hostname.includes('staging')) {
        return ENVIRONMENT.STAGING;
    }

    return ENVIRONMENT.PRODUCTION;
};
export class InvalidEnvironmentConfigError extends Error {
    constructor(environment: ENVIRONMENT, configName: string) {
        super(`Invalid config named ${configName} for environment ${environment}`);
        this.name = 'InvalidEnvironmentConfigError';
    }
}
