import { Avatar, MenuItem, SideMenu } from '@keyliving/component-lib';
import { determineEnvironment, ENVIRONMENT } from '@keyliving/utils';
import { ReactNode, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { authRoutes } from 'routes/routes';
import { URLS } from 'routes/urls';

import { useAuth } from '../../../hooks';
import classes from './AuthLayout.module.scss';
import Hamburger from './Hamburger';

interface AuthLayoutProps {
    children?: ReactNode;
}

export default function AuthLayout({ children }: AuthLayoutProps) {
    const { signOut, user } = useAuth();
    const navigate = useNavigate();

    const environment = determineEnvironment();
    const isDev = environment !== ENVIRONMENT.PRODUCTION;

    const utilityItems = useMemo(() => {
        return [
            <MenuItem
                className={classes['profile-menuitem']}
                icon={<Avatar name={user?.preferred_name || ''} size="md" />}
                key="profile"
                label={user?.preferred_name}
                path={URLS.Profile.path}
            />,
        ];
    }, [user]);

    const handleSignOut = () => {
        signOut().then(() => {
            navigate('/');
        });
    };

    return (
        <SideMenu
            darkTheme={isDev}
            defaultMenuOpen
            menuRoutes={authRoutes}
            onSignOut={handleSignOut}
            utilityMenuItems={utilityItems}
        >
            {children}

            <Hamburger darkTheme={isDev} />
        </SideMenu>
    );
}
