export enum TABLES {
    ASSETS = 'ASSETS',
    INVOICES = 'INVOICES',
}

export interface ColumnFilter {
    // Column id
    id: string;
    value: any;
}

export interface TableState {
    columnFilters?: ColumnFilter[];
    globalFilter?: any;
    sort?: { desc?: boolean | undefined; id: string }[];
}

export interface UpsertColumnFilterAction {
    data: ColumnFilter;
    table: TABLES;
}

export interface RemoveColumnFilterAction {
    // Column id
    id: string;
    table: TABLES;
}

export interface SetAllColumnFiltersAction {
    data: ColumnFilter[];
    table: TABLES;
}

export interface SetGlobalFilterAction {
    data: any;
    table: TABLES;
}
