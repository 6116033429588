import { IconProps } from './types';

export default function AddImageIcon({ height = 24, width = 24, ...rest }: IconProps) {
    return (
        <svg
            fill="none"
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M17.25 23.25a6 6 0 1 0 0-12 6 6 0 0 0 0 12ZM17.25 14.25v6M14.25 17.25h6"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                d="M8.25 20.25h-6a1.5 1.5 0 0 1-1.5-1.5V2.25a1.5 1.5 0 0 1 1.5-1.5h10.629a1.5 1.5 0 0 1 1.06.439l2.872 2.872a1.5 1.5 0 0 1 .439 1.06V8.25"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                d="M5.25 6.375a.375.375 0 1 1 0-.75M5.25 6.375a.375.375 0 1 0 0-.75"
                stroke="currentColor"
                strokeWidth="1.5"
            />
            <path
                d="M12.279 9.9 11.25 8.25a.749.749 0 0 0-1.238-.014l-2 2.85L6.78 10.1a.75.75 0 0 0-1.093.169l-1.937 3.98h4.5"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </svg>
    );
}
