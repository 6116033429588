import { IconProps } from './types';

export default function DashboardIcon({ height = 24, width = 24, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M21.6 0H2.4C.64 0 0 1.44 0 2.4V20c0 1.76 1.44 2.4 2.4 2.4h19.2c1.76 0 2.4-1.44 2.4-2.4V2.4C24 .64 22.56 0 21.6 0Zm-12 6.4h12.8v6.4H9.6V6.4ZM2.4 1.6h19.19c.37 0 .81.16.81.8v2.4H1.6V2.41c0-.37.16-.81.8-.81ZM1.6 20V6.4H8v14.4H2.41c-.37 0-.81-.16-.81-.8Zm20 .8h-12v-6.4h12.8v5.59c0 .37-.16.81-.8.81Z"
                fill="currentColor"
            />
        </svg>
    );
}
