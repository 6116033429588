import { IdType } from 'react-table';

import RadioGroup from '../../../RadioGroup';

interface BooleanFilterProps<T extends object = {}> {
    columnId: string;
    currentColumnFilters: { id: IdType<T>; value: boolean }[];
    label: string;
    labels?: Record<'true' | 'false', string>;
    setColumnFilter: (columnId: IdType<T>, filterValue: boolean) => void;
    stack?: 'vert' | 'horiz';
}

export default function BooleanFilter<T extends object = {}>({
    columnId,
    currentColumnFilters,
    label,
    labels,
    setColumnFilter,
    stack,
}: BooleanFilterProps<T>) {
    // Find the filter value for our columnId
    const columnFilterValue = currentColumnFilters.find(({ id }) => id === columnId)?.value;

    const selectedValue =
        typeof columnFilterValue === 'undefined' ? columnFilterValue : String(columnFilterValue);

    return (
        <RadioGroup
            controllable
            label={label}
            name={label}
            onChange={(e) => {
                const { value } = e.target;
                setColumnFilter(columnId, value === 'true');
            }}
            options={[
                {
                    value: 'true',
                    label: labels ? labels['true'] : 'Yes',
                },
                {
                    value: 'false',
                    label: labels ? labels['false'] : 'No',
                },
            ]}
            selected={selectedValue}
            stack={stack}
        />
    );
}
