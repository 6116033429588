import classes from './FormSectionHeader.module.scss';

interface FormSectionHeaderProps {
    headerIcon: React.ComponentType<{ height: number; width: number }>;
    text: string;
}

export default function FormSectionHeader({
    headerIcon: HeaderIcon,
    text,
}: FormSectionHeaderProps) {
    return (
        <div className={classes.header_container}>
            <div className={classes.icon_container}>
                <HeaderIcon height={18} width={18} />
            </div>
            <div className={classes.text_container}>{text}</div>
            <div className={classes.dashed_line} />
        </div>
    );
}
