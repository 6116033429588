import { numberOrNull, stringOrNull } from 'utils/validation';
import { number, object, preprocess, string } from 'zod';

import { REQUIRED_MSG } from '../../../../../../../../constants';

const validation = (
    contracts: Array<{ buildingSuiteContractConditionsId: string; value: number }>
) =>
    object({
        buildingSuiteContractConditionsId: preprocess(
            stringOrNull,
            string({
                required_error: REQUIRED_MSG,
                invalid_type_error: REQUIRED_MSG,
            })
        ).refine(
            (formValue) => {
                return contracts.every(
                    (contract) => contract.buildingSuiteContractConditionsId !== formValue
                );
            },
            {
                message: 'Contract condition of this type already exists.',
            }
        ),
        value: preprocess(
            numberOrNull,
            number({
                required_error: REQUIRED_MSG,
                invalid_type_error: REQUIRED_MSG,
            }).nonnegative()
        ),
    });

export default validation;
