import { IconProps } from './types';

export default function WrenchHammerIcon({ height = 24, width = 24, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <g
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            >
                <path d="m8.31 10.42-1.05-1a4.55 4.55 0 0 1-6.07-6.09l1.43 1.42a1.51 1.51 0 0 0 2.14-2.14L3.34 1.19a4.57 4.57 0 0 1 5.18.89M15.82 13.64l.92.91a4.55 4.55 0 0 1 6.07 6.05l-1.43-1.42a1.51 1.51 0 0 0-2.14 2.14l1.42 1.42a4.57 4.57 0 0 1-5.18-.89 4.52 4.52 0 0 1-.88-5.16l-.93-.92M1.44 22.59h0a2.24 2.24 0 0 1 0-3.16l4.7-4.68a.74.74 0 0 1 1.06 0l2.12 2.11a.74.74 0 0 1 0 1.05l-4.7 4.68a2.25 2.25 0 0 1-3.18 0ZM16.59 1.19l6.22 6.2a1.5 1.5 0 0 1 0 2.11l-2.12 2.11a1.51 1.51 0 0 1-2.12 0l-6.22-6.2a1.5 1.5 0 0 1 0-2.11l2.12-2.11a1.49 1.49 0 0 1 2.12 0ZM15.53 8.58 8.16 15.7" />
            </g>
        </svg>
    );
}
