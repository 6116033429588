import { IconProps } from './types';

export default function FollowToNewTabIcon({ height = 16, width = 16, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="-1 -1 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                d="M21.313 6.873V.686h-6.187M21.313.686l-13.75 13.75M10.313 4.81h-8.25A1.375 1.375 0 0 0 .688 6.187v13.75a1.375 1.375 0 0 0 1.375 1.375h13.75a1.375 1.375 0 0 0 1.375-1.375v-8.25"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
        </svg>
    );
}
