import { combineReducers, configureStore, PreloadedState } from '@reduxjs/toolkit';

import { api, authApi } from './modules/api';
import authReducer, { authListenerMiddleware } from './modules/auth';
import tableReducer from './modules/table';

// Create the root reducer separately so we can extract the RootState type
const rootReducer = combineReducers({
    [api.reducerPath]: api.reducer,
    [authApi.reducerPath]: authApi.reducer,
    auth: authReducer,
    table: tableReducer,
});

export function setupStore(initialState?: PreloadedState<RootState>) {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware()
                .prepend(authListenerMiddleware.middleware)
                .concat(api.middleware, authApi.middleware),
        preloadedState: initialState,
    });
}

// Inferred types
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
