import FileList, { FileListProps } from './FileList';
import FileListFile from './FileListFile';
import { UploadedFile } from './types';

export type UploadedListProps = Pick<
    FileListProps<UploadedFile>,
    'onClear' | 'onRemoveFile' | 'files'
>;

export default function UploadedList({ files = [], onRemoveFile, onClear }: UploadedListProps) {
    return (
        <FileList<UploadedFile>
            files={files}
            onClear={onClear}
            onRemoveFile={onRemoveFile}
            title="Uploaded Files"
        >
            {(files, onRemoveFile) =>
                files.map((upload) => {
                    const { file, key } = upload;

                    return (
                        <FileListFile
                            aria-label={`${file.name} uploaded successfully`}
                            handleRemove={() => onRemoveFile(upload)}
                            key={key}
                            label={file.name}
                        />
                    );
                })
            }
        </FileList>
    );
}
