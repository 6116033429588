import { getCurrentRootDomain, setCookie } from '@keyliving/utils';
import { createListenerMiddleware } from '@reduxjs/toolkit';

import { setCurrentUser } from './authModule';

/**
 * Setting this cookie helps us filter out internal users from analytics.
 *
 * @src https://www.analyticsmania.com/post/how-to-exclude-internal-traffic-in-google-analytics-4/#cookie
 */
const INTERNAL_USER_COOKIE_NAME = 'gtm_internal_user';
const oneYearFromNow = new Date(new Date().setFullYear(new Date().getFullYear() + 1));

export const authListenerMiddleware = createListenerMiddleware();

authListenerMiddleware.startListening({
    actionCreator: setCurrentUser,
    effect: (action) => {
        // Identify internal users
        setCookie(INTERNAL_USER_COOKIE_NAME, 'true', {
            expires: oneYearFromNow,
            domain: getCurrentRootDomain(),
        });
    },
});
