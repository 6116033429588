import { ContractCondition } from '@keyliving/shared-types';

/**
 * Sort the given contract conditions based on the description (label) in
 * ascending order
 */
export function sortContractConditionsAlphabetically(a: ContractCondition, b: ContractCondition) {
    const { description: aLabel } = a;
    const { description: bLabel } = b;

    if (!aLabel && !bLabel) {
        return 0;
    }

    if (!aLabel) {
        return 1;
    }

    if (!bLabel) {
        return -1;
    }

    return new Intl.Collator('en', { sensitivity: 'base' }).compare(aLabel, bLabel);
}
