import { NumberInput, RadioButtonTagGroup } from '@keyliving/component-lib';
import { CONTRACT_CONDITION_FORMULA } from '@keyliving/shared-types';
import { toPercent } from '@keyliving/utils';
import { ChangeEventHandler, FocusEventHandler, forwardRef } from 'react';
import { FieldError } from 'react-hook-form';

import classes from './ContractValueInput.module.scss';

interface ContractValueInputProps {
    disabled?: boolean;
    errors: {
        conditionId?: FieldError | undefined;
        value?: FieldError | undefined;
    };
    formula?: CONTRACT_CONDITION_FORMULA;
    label: string;
    name: string;
    onBlur?: FocusEventHandler<HTMLInputElement>;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    placeholder: string;
    /** Not the value to set on the input, but the current inputs value for display */
    value?: number;
}

const ContractValueInput = forwardRef<HTMLInputElement, ContractValueInputProps>(
    (
        {
            formula = CONTRACT_CONDITION_FORMULA.FIXED,
            errors,
            onBlur,
            onChange,
            name,
            label,
            placeholder,
            disabled = false,
            value = 0,
        },
        ref
    ) => {
        if (formula === CONTRACT_CONDITION_FORMULA.BINARY) {
            return (
                <RadioButtonTagGroup
                    disabled={disabled}
                    errors={errors}
                    label={label}
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    options={[
                        {
                            label: 'Enabled',
                            value: '1',
                        },
                        {
                            label: 'Disabled',
                            value: '0',
                        },
                    ]}
                    ref={ref}
                />
            );
        }

        if (formula === CONTRACT_CONDITION_FORMULA.PERCENTAGE) {
            return (
                <div className={classes.percentage}>
                    <div className={classes.percentage__input}>
                        <NumberInput
                            clearable
                            disabled={disabled}
                            errors={errors}
                            label={label}
                            name={name}
                            onBlur={onBlur}
                            onChange={onChange}
                            placeholder="0.00"
                            ref={ref}
                            step=".00000001"
                        />
                    </div>
                    <div className={classes.percentage__value}>= {toPercent(value)}</div>
                </div>
            );
        }

        if (formula === CONTRACT_CONDITION_FORMULA.YEAR) {
            return (
                <NumberInput
                    disabled={disabled}
                    errors={errors}
                    label={label}
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    ref={ref}
                    suffix="Years"
                />
            );
        }

        return (
            <NumberInput
                clearable
                disabled={disabled}
                errors={errors}
                label={label}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                placeholder={placeholder}
                prefix="$"
                ref={ref}
                step=".00000001"
            />
        );
    }
);

export default ContractValueInput;
