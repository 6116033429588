import { IconProps } from './types';

export default function PlusInCircleIcon({ height = 24, width = 24, ...rest }: IconProps) {
    return (
        <svg
            fill="none"
            height={height}
            viewBox="0 0 20 20"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_310_5088)">
                <path
                    d="M10 6.5625V13.4375"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <path
                    d="M6.5625 10H13.4375"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <path
                    d="M1.40625 10C1.40625 12.2792 2.31166 14.4651 3.9233 16.0767C5.53494 17.6883 7.7208 18.5937 10 18.5937C12.2792 18.5937 14.4651 17.6883 16.0767 16.0767C17.6883 14.4651 18.5937 12.2792 18.5937 10C18.5937 7.7208 17.6883 5.53494 16.0767 3.9233C14.4651 2.31166 12.2792 1.40625 10 1.40625C7.7208 1.40625 5.53494 2.31166 3.9233 3.9233C2.31166 5.53494 1.40625 7.7208 1.40625 10Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
            </g>
            <defs>
                <clipPath id="clip0_310_5088">
                    <rect fill="white" height={height} width={width} />
                </clipPath>
            </defs>
        </svg>
    );
}
